import Embla, { EmblaCarouselType } from "embla-carousel";

const initEmbla = (element: HTMLElement): void | EmblaCarouselType => {
  const carouselEmblaElement = element.querySelector<HTMLElement>(".embla");

  if (!carouselEmblaElement) return;

  const carousel = Embla(carouselEmblaElement, {
    loop: true,
    align: "start",
  });

  element.querySelector(".js-slider-left")?.addEventListener("click", () => {
    carousel.scrollPrev();
  });
  element.querySelector(".js-slider-right")?.addEventListener("click", () => {
    carousel.scrollNext();
  });

  if (carouselEmblaElement.closest(".js-winner-carousel")) return carousel;
};

export const initCarousels = () => {
  const elements = [".js-jury-carousel", ".js-gallery-videos"].map((item) =>
    document.querySelector<HTMLElement>(item),
  );

  const yearImagesElements =
    document.querySelectorAll<HTMLElement>(".js-gallery-images");

  const winnerCarouselElement = document.querySelector<HTMLElement>(
    ".js-winner-carousel",
  );

  [...yearImagesElements, ...elements].forEach(
    (item) => item && initEmbla(item),
  );

  const winnerCarousel =
    winnerCarouselElement && initEmbla(winnerCarouselElement);

  return winnerCarousel;
};
